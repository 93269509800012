import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  setToken(token: string): void {
    sessionStorage.setItem("token", token)
  }

  getToken(): string | null {
    return sessionStorage.getItem("token")
  }

  clearAll(): void {
    sessionStorage.clear();
  }
}
